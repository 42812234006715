import React from 'react';
import PropTypes from 'prop-types';

const CreateSketchBlock = ({ data }) => {
  const {
    highlightedLines,
    sketchUrl,
    showCodeOnly,
    visibleLines,
    hideLinesNumbers,
    height,
  } = data;

  if (!sketchUrl) {
    return null;
  }

  const params = [
    showCodeOnly && !visibleLines ? '&snippet' : '',
    visibleLines ? `&snippet=${ visibleLines }` : '',
    highlightedLines ? `#${ highlightedLines.replace(/\s/g, '') }` : '',
    hideLinesNumbers ? '&hideLinesNumbers' : '',
  ];

  return (
    <div className="sketch-block">
      <iframe
        src={`${ data.sketchUrl }/preview?embed${ params.join('') }`}
        style={{ height: `${ height }px` }}
        className="sketch-iframe"
        frameBorder={0}
      />
    </div>
  );
};

CreateSketchBlock.propTypes = {
  data: PropTypes.shape({
    sketchUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default CreateSketchBlock;
