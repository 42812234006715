import React, { useState, useRef } from 'react';
import path from 'path';
import { useIsVisible } from 'react-is-visible';

const GifPlayer = ({ data }) => {
  return (
    <div className="video">
      <video width="100%" height="100%" loop={ true } autoPlay={ true } muted={ true }>
        <source src={ data.video && data.video.url } type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const VideoPlayer = ({ data }) => {

  const [isPlaying, setPlaying] = useState(false);

  const nodeRef = useRef();
  const isVisible = useIsVisible(nodeRef);

  if (!isVisible && isPlaying) {
    nodeRef.current.pause();
  }

  return (
    <div className="video">
      <video
        ref={nodeRef}
        onPlay={ () => { setPlaying(true); } }
        onPause={ () => { setPlaying(false); } }
        width="100%" height="100%" controls={ true }
      >
        <source src={ data.video && data.video.url } type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const VideoBlock = ({ data }) => {

  if (!data.video) {
    return null;
  }

  const filename = path.basename(data.video.url);

  const renderVideo = () => {
    if ( filename ) {
      if ( filename.includes('gif') ) {
        return <GifPlayer data={data} />;
      } else {
        return <VideoPlayer data={data} />;
      }
    }
  };

  return (
    renderVideo()
  );
};


export default VideoBlock;
