import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class StepByStepBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: 0,
      lightbox: false,
    };
  }

  componentDidMount() {
    this.targetElement = document.querySelector('#lightbox');
  }

  totalSlides() {
    return this.props.data.instructionSteps.length;
  }

  currentSlide() {
    return this.state.showing;
  }

  handleSwipeLeft() {
    const newSlide = (this.totalSlides() + this.currentSlide() - 1) % this.totalSlides();
    this.setState({ showing: newSlide });
  }

  handleSwipeRight() {
    const newSlide = (this.totalSlides() + this.currentSlide() + 1) % this.totalSlides();
    this.setState({ showing: newSlide });
  }

  handleSetSlide(index) {
    this.setState({ showing: index });
  }

  openLightBox() {
    this.setState({ lightbox: true });
    disableBodyScroll(this.targetElement);
  }

  closeLightBox() {
    this.setState({ lightbox: false });
    enableBodyScroll(this.targetElement);
  }

  render() {
    const { instructionSteps } = this.props.data;
    const { showing, lightbox } = this.state;

    const slides = instructionSteps.map(step => {
      const { image, descriptionNode, htmlNode } = step;
      const html = htmlNode.childMarkdownRemark.html;
      const description = descriptionNode.childMarkdownRemark.html;
      return (
        <div className="step-block-item">
          {
            html ?
              <div
                className="formatted-content text-block-text" dangerouslySetInnerHTML={ { __html: html } }
              />
              :
              image.format === 'mp4' ?
                <video width="100%" height="100%" autoPlay loop>
                  <source src={ image.url } type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                :
                <img className="step-block-img" src={ image.url } />
          }
          <div className="step-block-stripe">
            <div className="step-block-arrow-left" onClick={ this.handleSwipeLeft.bind(this) }>
              <i className="step-block-icon-left" />
            </div>
            <div className="step-block-arrow-right" onClick={ this.handleSwipeRight.bind(this) }>
              <i className="step-block-icon-right" />
            </div>
          </div>
          {
            description &&
              <div className="step-block-text">
                <div className="step-block-text-content" dangerouslySetInnerHTML={ { __html: description } } />
              </div>
          }
        </div>
      );
    });

    return (
      <div className="step-block">
        <div className="step-block-fullscreen" onClick={ this.openLightBox.bind(this) }>
          <i className="icon-fullscreen" />
        </div>
        { slides[showing] }
        <ul className="step-block-nav">
          {
            Array(slides.length).fill('_').map((_, index) =>
              <li
                className={ `step-block-nav-item ${ showing === index ? ' is-current' : '' }` }
                onClick={ this.handleSetSlide.bind(this, index) }
              >
                step { index + 1 }
              </li>,
            )
          }
        </ul>
        {
          lightbox &&
            <div id='lightbox' className="lightbox">
              <div className="lightbox-content">
                <div className="lightbox-image">
                  <div className="step-block-close-fullscreen" onClick={ this.closeLightBox.bind(this) }>
                    <i className="icon-fullscreen_close" />
                  </div>
                  { slides[showing] }
                  <ul className="step-block-nav">
                    {
                      Array(slides.length).fill('_').map((_, index) =>
                        <li
                          className={ `step-block-nav-item ${ showing === index ? ' is-current' : '' }` }
                          onClick={ this.handleSetSlide.bind(this, index) }
                        >
                          step { index + 1 }
                        </li>,
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

StepByStepBlock.propTypes = {
  data: PropTypes.shape({
    instructionSteps: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.object,
      description: PropTypes.string,
    })),
  }).isRequired,
};

export default StepByStepBlock;
