import React from 'react';
import PropTypes from 'prop-types';
import TextBlock from './modularContents/TextBlock';
import VideoBlock from './modularContents/VideoBlock';
import ImageBlock from './modularContents/ImageBlock';
import StepByStepBlock from './modularContents/StepByStepBlock';
import NoteBlock from './modularContents/NoteBlock';
import MaterialListBlock from './modularContents/MaterialListBlock';
import CreateSketchBlock from './modularContents/CreateSketchBlock';
import MatlabCodeBlock from './modularContents/MatlabCodeBlock';

const blockify = (block, index) => {
  switch (block.__typename) {
  case 'DatoCmsText':
    return(
      <div key={index} className='section-right'>
        <TextBlock data={ block } />
      </div>
    );
  case 'DatoCmsVideo':
    return(
      <div key={index} className='section-right'>
        <VideoBlock data={ block } />
      </div>
    );
  case 'DatoCmsImage':
    return(
      <div key={index} className='section-right'>
        <ImageBlock data={ block } />
      </div>
    );
  case 'DatoCmsCreateSketch':
    return(
      <div key={index} className='section-right'>
        <CreateSketchBlock data={ block } />
      </div>
    );
  case 'DatoCmsStepByStep':
    return(
      <div key={index} className='section-right-full'>
        <StepByStepBlock data={ block } />
      </div>
    );
  case 'DatoCmsNote':
    return(
      <div key={index} className='section-right'>
        <NoteBlock data={ block } />
      </div>
    );
  case 'DatoCmsMaterialList':
    return(
      <div key={index} className='section-right'>
        <MaterialListBlock data={ block } />
      </div>
    );
  case 'DatoCmsMatlabCode':
    return(
      <div key={index} className='section-right'>
        <MatlabCodeBlock data={ block } />
      </div>
    );
  default:
    return(
      <div key={index} className='section-right'>
        <div key={ index }>{block.__typename}</div>
      </div>
    );
  }
};


const Paragraph = data => {
  return <div>
    {
      data.blocks.map((block, index) => blockify(block, index))
    }
  </div>;
};

Paragraph.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default Paragraph;
