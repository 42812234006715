import React from 'react';
import PropTypes from 'prop-types';

const TitleBlock = ({ data }) => {
  return (
    <h4 className="title-block-title">{ data.title }</h4>
  );
};

TitleBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default TitleBlock;
