import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Img from 'gatsby-image';

class ImageBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showing: 0,
      dropdown: false,
      lightbox: false,
    };
  }

  componentDidMount() {
    this.targetElement = document.querySelector('#lightbox');
  }

  openLightBox() {
    this.setState({ lightbox: true });
    disableBodyScroll(this.targetElement);
  }

  closeLightBox() {
    this.setState({ lightbox: false });
    enableBodyScroll(this.targetElement);
  }

  render(){
    const { data } = this.props;
    const { lightbox } = this.state;
    const imagePath = data.image.fluid ? data.image.fluid : data.image.url;
    return (
      <div className="image-block">
        <div onClick={ this.openLightBox.bind(this) }>
          {
            imagePath &&
              <Img className="image-block-img" fluid={ imagePath } />
          }
        </div>
        {
          data.description && <p>{ data.title }</p>
        }
        {
          lightbox &&
            <div id='lightbox' className="lightbox">
              <div className="lightbox-content">
                <div className="lightbox-image">
                  <div className="step-block-close-fullscreen" onClick={ this.closeLightBox.bind(this) }>
                    <i className="icon-fullscreen_close" />
                  </div>
                  { imagePath &&
                  <Img className="image-block-img" fluid={ imagePath } /> }
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}


ImageBlock.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default ImageBlock;
