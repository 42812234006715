import React, { Component } from 'react';
import slugify from 'slugify';
import { Link } from 'react-scroll';
import { RemoveScroll } from 'react-remove-scroll';

class SideMenu extends Component {

  constructor() {
    super();
    this.state = {
      blockScroll: false,
    };
  }

  handleMouseEnter() {
    this.setState({ blockScroll: true });
  }

  handleMouseLeave() {
    this.setState({ blockScroll: false });
  }

  render() {
    const { menuLabels, type, title } = this.props;
    return (
      <RemoveScroll enabled={ this.state.blockScroll } removeScrollBar={ false }>
        <header className="sidebar-header">
          <div className="sidebar-pre-title">{type}</div>
          <div className="sidebar-title">{title}</div>
        </header>
        <nav id='sidebar' className="sidebar-body"
          onMouseEnter={this.handleMouseEnter.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
        >
          <ul className="sidebar-list">
            {
              menuLabels.map(label => {
                return (
                  <li className="sidebar-list-item" key={label}><Link activeClass="active" offset={-50} smooth={true} spy={true} to={`${ slugify(typeof label === 'string' ? label : '') }`} className="sidebar-list-link">
                    {label}
                  </Link></li>
                );
              })
            }
          </ul>
        </nav>
      </RemoveScroll>
    );
  }
}

export default SideMenu;
