import React from 'react';
import PropTypes from 'prop-types';

const TextBlock = ({ data }) => {
  const txtHtml = data.textNode.childMarkdownRemark.html;
  return (
    <div className="text-block">
      {
        data.title && <h4 className="text-block-title">{ data.title }</h4>
      }
      <div
        className="formatted-content text-block-text" dangerouslySetInnerHTML={ { __html: txtHtml } }
      />
    </div>
  );
};

TextBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default TextBlock;
