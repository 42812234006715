import React from 'react';
import Paragraph from './Paragraph';
import TitleBlock from './modularContents/TitleBlock';
import slugify from 'slugify';

const separatedByTitle = section => {
  let i = 0;

  return section.blocks.reduce((acc, item) => {
    if (item.__typename === 'DatoCmsTitle') {
      acc = acc.concat({ titleBlock: item, items: [] });
      i += 1;
    } else {
      acc[i].items = [ ...acc[i].items || [], item ];
    }
    return acc;
  }, [{}]);
};

const Section = ({ section }) => {
  return (
    <div className='section' id={ slugify(section.title || '') }>
      <div className="section-header">
        {
          section.label &&
            <p className="section-label">{ section.label }</p>
        }
        <h2 className="section-title">{ section.title }</h2>
      </div>
      {
        separatedByTitle(section).map((par, index) => {
          return (
            <div className="section-body" key={`paragraph-${ index }`}>
              {
                par.titleBlock &&
                  <div className='section-left'>
                    <TitleBlock data={ par.titleBlock } />
                  </div>
              }
              <Paragraph blocks={par.items || []} />
            </div>
          );
        })
      }
    </div>
  );
};

export default Section;
