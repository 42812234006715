import React from 'react';
import PropTypes from 'prop-types';

const MaterialListBlock = ({ data }) => {
  const { materials, title, description } = data;
  return (
    <div className="space-bottom-2">
      {
        title &&
          <h4>{ title }</h4>
      }
      {
        description &&
          <p>{ description }</p>
      }
      <ul className="materials-list">
        {
          materials.map(listEntry => {
            const { material, quantity } = listEntry;
            const { name, picture, id } = material;
            const backgroundImage = picture && picture.url;

            return (
              <li
                key={ id }
                className="materials-list-item"
              >
                <div className='materials-list-content'>
                  <img className='materials-list-image' src={ backgroundImage } />
                  <div className='materials-list-text'>
                    <span className='materials-list-quantity'>{ quantity }</span>
                    <span>{ name }</span>
                  </div>
                </div>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

MaterialListBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      material: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        picture: PropTypes.object,
        glossaryLink: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }),
      }),
    })),
  }).isRequired,
};

export default MaterialListBlock;
