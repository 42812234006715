import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';

const MatlabCodeBlock = ({ data }) => {
  const content = `${ data.content }`;
  const strippedString = content
    .replace(/```matlab\n/, '')
    .replace('```', '');
  return (
    <div className="matlab-code-block">
      <SyntaxHighlighter
        className="formatted-content"
        language='matlab'
      >
        {strippedString}
      </SyntaxHighlighter>
    </div>
  );
};

MatlabCodeBlock.propTypes = {
  data: PropTypes.shape({
    contentNode: PropTypes.object.isRequired,
  }).isRequired,
};

export default MatlabCodeBlock;
