import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Section from '../components/Section';
import SideMenu from '../components/SideMenu';
import ScrollContext from '../context/ScrollContext';

class Chapter extends Component {
  render() {
    const { index, prevPage, nextPage, prevPageSlug, nextPageSlug, course, slugs, locale } = this.props.pageContext;
    const {
      title,
      cover,
      description,
      sections,
    } = this.props.data.chapter;

    const menuLabels = sections.map(sec => sec.title);
    const coverPath = cover.format === 'gif' ? cover.url : cover.fluid.src;
    return (
      <ScrollContext.Consumer>
        {scroll => (
          <Layout locale={locale} slugs={slugs}>
            <div className="block-page wrap--on-xl space--top-2">
              <div className={ `block-page-aside ${ !scroll.showHeader && 'upper' }` }>
                <div className="sidebar">
                  <SideMenu menuLabels={menuLabels} title={title} type={`Chapter ${ index }`} />
                </div>
              </div>
              <div className="block-page-content">
                <div className="wrap--til-xl">
                  <div className="main-content">
                    <div
                      className="module-header-image"
                      style={{ backgroundImage: `url(${ coverPath })` }}
                    >
                      <div className="module-header-title">
                        <h1 className="module-title">{title}</h1>
                      </div>
                    </div>
                    <div className="lesson-body">
                      {sections.map(section => (
                        <Section section={section} key={section.id} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className='opposite-items'>
                  <div>
                    {
                      prevPageSlug && prevPageSlug[0] && prevPage &&
                        <Link to={ prevPageSlug[0].path }>
                          <i className="icon-arrow-circle-left" />
                        </Link>
                    }
                  </div>
                  <div>
                    {
                      nextPageSlug && nextPageSlug[0] && nextPage &&
                        <Link to={ nextPageSlug[0].path }>
                          <button className='store-button'>{nextPage.title}</button>
                        </Link>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        )}
      </ScrollContext.Consumer>
    );
  }
}

Chapter.propTypes = {
  data: PropTypes.shape({
    chapter: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.string,
      cover: PropTypes.object,
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          blocks: PropTypes.array,
        }),
      ),
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    index: PropTypes.number.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ChapterQuery($slug: String!, $locale: String!) {
    chapter: datoCmsChapter(slug: { eq: $slug }, locale: { eq: $locale }) {
      id
      slug
      title
      cover {
        url
        format
        fluid(
          maxWidth: 3000
          imgixParams: { w: "3000", dpr: 2.0, auto: "compress" }
        ) {
          src
        }
        url
      }
      description
      sections {
        id
        label
        title
        blocks {
          ... on DatoCmsText {
            ...lessonModularContentText
          }
          ... on DatoCmsImage {
            ...lessonModularContentImage
          }
          ... on DatoCmsVideo {
            ...lessonModularContentVideo
          }
          ... on DatoCmsTitle {
            ...lessonModularContentTitle
          }
          ... on DatoCmsMaterialList {
            ...lessonModularContentMaterialList
          }
          ... on DatoCmsCreateSketch {
            ...lessonModularContentCreateSketch
          }
          ... on DatoCmsNote {
            ...lessonModularContentNote
          }
          ... on DatoCmsMatlabCode {
            ...lessonModularContentMatlabCode
          }
        }
      }
    }
  }
  fragment lessonModularContentText on DatoCmsText {
    title
    text
    textNode {
      childMarkdownRemark {
        html
      }
    }
  }

  fragment lessonModularContentNote on DatoCmsNote {
    content
    contentNode {
      childMarkdownRemark {
        html
      }
    }
  }

  fragment lessonModularContentMatlabCode on DatoCmsMatlabCode {
    content
    contentNode {
      childMarkdownRemark {
        html
      }
    }
  }

  fragment lessonModularContentImage on DatoCmsImage {
    image {
      format
      url
      fluid(
        maxWidth: 3000,
        imgixParams: { w: "3000", dpr: 2.0,  auto: "compress" }
      ) {
        src
        ...GatsbyDatoCmsFluid
      }
    }
    description
  }

  fragment lessonModularContentVideo on DatoCmsVideo {
    video {
      url
    }
  }

  fragment lessonModularContentTitle on DatoCmsTitle {
    title
  }

  fragment lessonModularContentCreateSketch on DatoCmsCreateSketch {
    sketchUrl
    showCodeOnly
    highlightedLines
    visibleLines
    hideLinesNumbers
    height
  }

  fragment lessonModularContentMaterialList on DatoCmsMaterialList {
    id
    title
    materials {
      quantity
      material {
        id
        name
        description
        picture {
          fixed(
            height: 270
            width: 270
            imgixParams: { h: "270", fm: "jpg", auto: "compress" }
          ) {
            src
            srcSet
            width
            height
          }
          fluid(
            imgixParams: {
              h: "220"
              fit: "crop"
              crop: "focal-point"
              fm: "jpg"
              auto: "compress"
            }
          ) {
            src
          }
          url
        }
      }
    }
  }
`;

export default Chapter;
